import React,{ useState, useEffect } from 'react';
import metamask from '../images/metamask.svg';
import "./Footer.css";
import Web3 from "web3";

const Footer = (props) => {
    let netId;
    const supportedIds = [761412];
    const [web3, setWeb3] = useState(null);
    const [networkID, setNetworkID] = useState('761412');
    const [modal, setModal] = useState(false);
    // const [modalHeader, setModalHeader] = useState('Metamask Required');
    // const [modalBody, setModalBody] = useState('This dApp requires Metamask to interact with Ethereum blockchain. Please consider installing Metamask extension on your browser. :)')

    // const modalToggle = () => setModal(!modal);

  
    const addNetwork = async() => {        
        var instance;
        if (window.ethereum) {
          // set up a new provider
            try {
                instance = new Web3(window.ethereum);
            } catch (error) {
                console.error(error);
            }
        } else if (window.web3) {
            instance = new Web3(window.web3);
        } else {
            // modalToggle();
            alert('This dApp requires Metamask to interact with Ethereum blockchain. Please consider installing Metamask extension on your browser.');
            return;
        }
        setWeb3(instance);
        netId = await instance.eth.net.getId()
        if(!supportedIds.includes(netId)){
            var params = [{
                chainId: '0xB9E44',
                chainName: 'Miexs Smartchain Mainnet',
                nativeCurrency: {
                    name: 'MIX',
                    symbol: 'MIX',
                    decimals: 18
                },
                rpcUrls: ['https://mainnet-rpc.miexs.com/'],
                blockExplorerUrls: ['https://miexs.com/']
            }]
            window.ethereum.request({ method: 'wallet_addEthereumChain', params })
                    .then(() => console.log('Success'))
                    .catch((error) => {                        
                        console.log("Error", error.message);
                        alert(error.message);
                        return;
                    });
        }else{
            alert("Miexs Chain Network has already been added to Metamask.");
            return;
        }
    }

    return (
        <footer>
            <div className="container-footer ">
                <p>Powered by MIX</p>
                <button type="button" class="btn-dark float-right" onClick={()=> addNetwork()}>
                    <img class="" width="20" src={metamask} alt="Metamask" /> Add Miexs Mainnet Network
                </button>
            </div>
        </footer>
    );
}

export default Footer;